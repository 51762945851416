<template>
  <div id="header-titles" v-bind:class="'flex justify-between gap-4 items-center'">
    <a
      v-for="(title, index) in titles" v-bind:key="title.title"
      :href="title.link"
      class="font-body text-bodyL border rounded-full px-3 py-1 dark:text-halfColonialWhite text-sepiaBlack hover:bg-redDamask active:bg-redDamask hover:bg-opacity-20 active:bg-opacity-20 outline-none focus:outline-none active:outline-none"
      :class="isActive(title.link) ? 'bg-redDamask bg-opacity-20 border-redDamask' : 'border-transparent'"
    >
      <span class="mr-2">{{ (index + 1).toString().padStart(2, '0') }}.</span>
      {{ title.title }}
    </a>
  </div>
</template>

<script>
  export default {
    name: 'HeaderTitles',

    props: {
      titles: {
        type: Array,
        required: false,
      },
    },

    methods: {
      isActive(link) {
        return window.location == link;
      },
    },
  }
</script>