<template>
  <a @click="this.$router.go(-1)" class="flex gap-2 bg-redDamask border-transparent rounded-full max-w-max cursor-pointer pr-1 pl-3 py-1">
    <span class="inline-block font-body text-bodyL dark:text-halfColonialWhite text-sepiaBlack my-auto">{{ $t('entity.close') }}</span>
    <BaseIcon name="closeWhite" outlineColor="white" size="w-10 h-10" />
  </a>
</template>

<script>
  import BaseIcon from '@/components/BaseIcon.vue';

  export default {
    name: 'BackToList',
    components: {
      BaseIcon
    }
  }
</script>
