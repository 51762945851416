<template>
  <div id="header" v-bind:class="'flex justify-between items-center bg-mainGrey px-11 pt-5 sticky top-0 z-50 sticy-header-background'">
    <BaseIcon class="mr-20" :name="iconName" bg="bg-twine bg-opacity-20" v-on:click="navigate_home"/>
    <HeaderTitles v-if="titlesVisibility == true" :titles="subtitles" />
    <div v-if="languageVisibility == true">
       <Language />
    </div>
  </div>
</template>

<script>
  import Language from "@/components/Language.vue";
  import BaseIcon from '@/components/BaseIcon.vue';
  import BackToList from "@/components/tablet/BackToList.vue";
  import HeaderTitles from "./HeaderTitles.vue";
  import { titleStore } from '../store/store.js';

  export default {
    name: 'Header',

    components: {
      Language,
      BaseIcon,
      BackToList,
      HeaderTitles,
    },

    props: {
      languageVisibility: {
        type: Boolean,
        required: false,
        default: true,
      },
      titlesVisibility: {
        type: Boolean,
        required: false,
        default: true,
      },
    },

    data() {
      return {
        iconName: 'homeWhite',
        subtitles: titleStore.exhibitionSubtitles,
        titleStore,
      }
    },

    methods: {
      navigate_home() {
        this.$router.push({ path: '/menu' });
      }
    },

    watch: {
      titleStore: {
        handler: function (val) {
          this.subtitles = val.exhibitionSubtitles;
        },
        deep: true
      }
    }
  }
</script>