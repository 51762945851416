<template>
<!-- This is the version displayed on the landing page (TV.vue) -->
<div v-if="flag">
  <div class="flex justify-center gap-x-6">
    <button 
      v-for="lang in supportedLocales"
      :key="lang"
      :id="lang + 'Btn'"
      class="font-body text-2xl border border-transparent rounded-2xl p-3.5 outline-none focus:outline-none active:outline-none" 
      v-on:click="langSelect(lang)"
      :class="isActive(lang) ? 'bg-twine bg-opacity-20 text-white' : 'text-redDamask'">
      <img :src="require(`@/assets/${lang}.png`)" :alt="lang" class="mb-3.5" :class="isActive(lang) ? 'border-2 rounded-full border-redDamask p-0.5' : ''">
      {{ lang.toUpperCase() }}
    </button>
  </div>
</div>
<!-- This is the header version -->
<div v-else>
  <div class="flex justify-center">
    <button 
      v-for="lang in supportedLocales"
      :key="lang"
      :id="lang + 'Btn'" 
      class="font-body text-bodyL border rounded-full w-10 h-10 hover:bg-redDamask active:bg-redDamask hover:bg-opacity-20 active:bg-opacity-20 outline-none focus:outline-none active:outline-none" 
      v-on:click="langSelect(lang)"
      :class="isActive(lang) ? 'bg-redDamask bg-opacity-20 border-redDamask dark:text-halfColonialWhite text-sepiaBlack' : 'border-transparent opacity-50 dark:text-white'">{{ lang.toUpperCase() }}
    </button>
  </div>
</div>
</template>

<script>
import { titleStore } from '../store/store.js';
import { languageStore } from '../store/store.js';

export default {
  name: 'Language',

  props: {
    flag: {
      type: Boolean,
      default: false
    } 
  },

  data() {
    return {
      currentLang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
    }
  },

  methods: {
    isActive(lang) {
      return this.currentLang == lang;
    },

    langSelect: function(lang) {
      if(lang !== this.currentLang) {
        this.$emit('langchanged', lang);
        window.localStorage.language = lang;
        this.$i18n.locale = lang;
        this.currentLang = lang;
        languageStore.setLanguage(lang);

        // update titles
        titleStore.getMainTitle(lang);
        titleStore.getSubtitles(lang);
      }
    }
  },

  beforeMount() {
    // get supported locales from .env
    this.supportedLocales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',') || '';
  },
}
</script>
